import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  capitalizeStringWithSpaces,
  uploadAiImportDocument,
  currentPortfolioSelector,
  getAiImportExtractedData,
  getSortKeyBetween,
  getUuid,
  getTickerUsingId,
  store,
  custodianSelector,
  sheetSelector,
  sectionSelector,
  sheetSectionsSelector,
  updateCustodian,
  getTickerUsingShortName,
  insertCustodianAtEndOfSection,
  updateDashboardAction,
  custodianSheetSelector,
  insertSection,
  updateUserPreferences,
  userPreferencesSelector,
  exchangeCodeToNameMap,
  costType,
  getExchangeRate,
  PVST_VALUE_TICKER_ID,
  getExchangeRateDetails
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import { ReactComponent as AiDocumentImportIcon } from "assets/images/ai_document_import.svg";
import { ReactComponent as AiDocumentImportErrorIcon } from "assets/images/ai_document_import_error.svg";
import DragDropInput from "components/inputs/DragDropInput";
import FileUploadInput from "components/inputs/FileUploadInput";
import SecondaryButton from "components/button/SecondaryButton";
import FancyLoader from "components/loader/FancyLoader";
import { getFileNameWithoutExtension } from "utilities/FileUtils";
import PrimaryButton from "components/button/PrimaryButton";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import TickersPickerDialog from "components/planning/variable_dialogs/TickersPickerDialog";
import CustodiansPickerDialog from "components/planning/variable_dialogs/CustodiansPickerDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { hashParams } from "routes";

const FILE_UNSUPPORTED_ERROR = "FILE_UNSUPPORTED_ERROR";

const supportedFileTypes = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/webp",
  "text/plain",
  "text/csv",
  "application/json"
];

const MAX_FILE_SIZE = 1024 * 1024 * 10;

const ImportDialog = styled(Dialog)`
  position: relative;
  width: 1000px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 35px
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  width: 666px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  max-width: 600px;
  flex: 1;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const AiDocumentImportIconComponent = styled(AiDocumentImportIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const AiDocumentImportErrorIconComponent = styled(AiDocumentImportErrorIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DocumentUploadContainer = styled(DragDropInput)`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 25px 25px 50px 25px;
`;

const CommandArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.documentsDetailsCommandAreaBG};
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === false ? "visible" : "hidden")};
`;

const UploadButtonContainer = styled.div`
  margin: ${props => props.margin};
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UploadButton = styled(FileUploadInput)`
  top: 168px;
  left: 0;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: #ffffff;
  background: #000000;
  cursor: pointer;
  outline: 0;
  border: 0;
`;

const UploadHint = styled.div`
  margin-top: 8px;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  align-self: center;
  white-space: pre-wrap;
  font-size: 11px;
  font-weight: 800;
  line-height: 13.31px;
  text-align: center;
  opacity: 0.5;
  text-transform: uppercase;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SubTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AbortButton = styled(SecondaryButton)`
  min-width: 40px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
`;

const SubTitle = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
`;

const UploadLoader = styled(FancyLoader)`
  flex: 1;
  margin-top: 10px;
`;

const ReportToKubera = styled(PrimaryButton)`
  margin-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ImportButton = styled(PrimaryButton)`
  min-width: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 12px;
  height: 35px;
`;

const ExtractedDataDescription = styled.div`
  margin-top: 6px;
  color: #7a7a7a;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const GridContainer = styled.div`
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const CellDropDownContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 4px;
`;

const TickerCellPlaceholder = styled.div`
  opacity: 0.5;
  margin-right: 4px;
`;

const EmptyCellIndicator = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: right;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -22px;
  margin-bottom: 9px;
`;

const BadgeText = styled.div`
  display: flex;
  height: 15px;
  background: ${props => (props.isUpdate === true ? "#AFF2D8" : "#FF0202")};
  color: ${props => (props.isUpdate === true ? "#00000099" : "#FFFFFF")};
  width: 100%;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  text-align: right;
  font-weight: 400;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0%;
  text-transform: uppercase;
`;

class AiDocumentImportComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      isUploading: false,
      files: null,
      error: null,
      dataExtracted: null,
      assetGridData: null,
      debtGridData: null,
      showTickerPickerForAssetRow: null,
      showLocationPickerForAssetRow: null,
      showLocationPickerForDebtRow: null,
      showSuccessfulImportDialog: false
    };

    this.locationMap = {};

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleReportToKuberaClick = this.handleReportToKuberaClick.bind(this);
    this.handleAssetRowContextMenuSelection = this.handleAssetRowContextMenuSelection.bind(this);
    this.handleTickerSelection = this.handleTickerSelection.bind(this);
    this.handleTickerPickerDismiss = this.handleTickerPickerDismiss.bind(this);
    this.handleAssetLocationSelection = this.handleAssetLocationSelection.bind(this);
    this.handleDebtLocationSelection = this.handleDebtLocationSelection.bind(this);
    this.handleLocationPickerDismiss = this.handleLocationPickerDismiss.bind(this);
    this.handleImportButtonClick = this.handleImportButtonClick.bind(this);
    this.handleSuccessfulImportDialogDismiss = this.handleSuccessfulImportDialogDismiss.bind(this);

    if (!this.props.files === false) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.files !== this.props.files) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  handleOverlayDismiss(showConfirmation = true) {
    if (showConfirmation) {
      this.setState({ showDismissConfirmation: true });
    } else {
      this.props.onDismiss();
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragStop() {
    this.setState({ isDragging: false });
  }

  getLocationPath(isAsset, location) {
    if (!location === true) {
      return isAsset ? i18n.t("aiImport.defaultAssetPath") : i18n.t("aiImport.defaultDebtPath");
    }
    if (location.isCustodian) {
      return `${location.sheet.name} / ${location.section.name}`;
    } else if (location.isSection) {
      return `${location.sheet.name} / ${location.name}`;
    } else if (location.isSheet) {
      return `${location.name}`;
    }
    return null;
  }

  handleFileUpload(files) {
    if (!files === true || files.length === 0) {
      return;
    }

    if (files[0].size > MAX_FILE_SIZE || !supportedFileTypes.includes(files[0].type)) {
      this.setState({ error: FILE_UNSUPPORTED_ERROR });
      return;
    }

    let defaultAssetLocation = null;
    let defaultDebtLocation = null;
    this.setState({ files, isUploading: true });
    this.props
      .uploadAiImportDocument(this.props.currentPortfolio.id, files[0])
      .then(apiData => {
        return this.props.getAiImportExtractedData(apiData.id);
      })
      .then(apiData => {
        const setDefaultLocation = (itemCategory, item) => {
          let matchedCustodian = item.matchedCustodianId
            ? custodianSelector(store.getState(), item.matchedCustodianId, this.props.currentPortfolio.id)
            : null;
          if (!matchedCustodian === false) {
            const custodian = custodianSelector(
              store.getState(),
              item.matchedCustodianId,
              this.props.currentPortfolio.id
            );
            const section = sectionSelector(store.getState(), custodian?.sectionId);
            const sheet = sheetSelector(store.getState(), section?.sheetId);
            if (!custodian || !section || !sheet) {
              return;
            }
            this.locationMap[item.id] = {
              isCustodian: true,
              section: section,
              sheet: sheet,
              ...custodian
            };
          } else if (itemCategory === category.ASSET && !defaultAssetLocation === false) {
            this.locationMap[item.id] = defaultAssetLocation;
          } else if (itemCategory === category.DEBT && !defaultDebtLocation === false) {
            this.locationMap[item.id] = defaultDebtLocation;
          } else {
            const sheet =
              this.props.defaultTargetSheet?.category === itemCategory
                ? this.props.defaultTargetSheet
                : this.props.currentPortfolio.details.sheet.filter(item => item.category === itemCategory)[0];
            const sections = sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, sheet.id);
            const section = {
              id: getUuid(),
              name: getFileNameWithoutExtension(apiData.name),
              sheetId: sheet.id,
              sortKey: getSortKeyBetween(sections[sections.length - 1]?.sortKey, null)
            };

            const defaultLocation = {
              isSection: true,
              isUpdateSection: true,
              section: section,
              sheet: sheet,
              ...section
            };

            if (itemCategory === category.ASSET) {
              defaultAssetLocation = defaultLocation;
              this.locationMap[item.id] = defaultAssetLocation;
            } else {
              defaultDebtLocation = defaultLocation;
              this.locationMap[item.id] = defaultDebtLocation;
            }
          }
        };

        for (const asset of apiData.dataExtracted.asset) {
          setDefaultLocation(category.ASSET, asset);
        }
        for (const debt of apiData.dataExtracted.debt) {
          setDefaultLocation(category.DEBT, debt);
        }

        this.setState({
          isUploading: false,
          dataExtracted: apiData.dataExtracted,
          assetGridData: this.getAssetGridData(this.props.currentPortfolio.currency, apiData.dataExtracted.asset),
          debtGridData: this.getDebtGridData(this.props.currentPortfolio.currency, apiData.dataExtracted.debt)
        });
      })
      .catch(apiError => {
        this.setState({ isUploading: false, error: apiError });
      });
  }

  handleReportToKuberaClick(e) {
    const mailToLink = `mailto:hello@kubera.com?subject=${encodeURIComponent("AI Import Fail")}`;
    window.kuberaOpen(mailToLink);
  }

  getFileName(file, removeExtension = false) {
    return capitalizeStringWithSpaces(removeExtension ? getFileNameWithoutExtension(file.name) : file.name);
  }

  getRowsToExtract() {
    return (
      this.state.assetGridData.sheets[0].sections[0].rows.length +
      this.state.debtGridData.sheets[0].sections[0].rows.length
    );
  }

  handleAssetChange(newGridData) {
    this.setState({ assetGridData: newGridData });
  }

  getNameFromExtractedData(item) {
    return (item?.institution ? `${item.institution} - ` : "") + item?.name;
  }

  getAssetDetails(asset) {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);
    if (asset.quantity !== undefined && asset.tickerId) {
      return {
        value: asset.quantity,
        valueTickerId: asset.tickerId,
        currency: getTickerUsingId(asset.tickerId).shortName
      };
    } else if (asset.quantity !== undefined && !asset.tickerId && asset.price !== undefined && asset.priceTickerId) {
      return {
        value: asset.quantity,
        valueTickerId: PVST_VALUE_TICKER_ID,
        currency: getTickerUsingId(PVST_VALUE_TICKER_ID).shortName,
        valueExchangeRate: getExchangeRateDetails(
          portfolioTicker.id,
          getExchangeRate(getTickerUsingId(asset.priceTickerId).shortName, portfolioTicker.shortName)
        ),
        rate: JSON.stringify({ u: 1, t: asset.priceTickerId, p: asset.price })
      };
    } else if (asset.quantity !== undefined && !asset.tickerId && !asset.price && !asset.value) {
      return {
        value: asset.quantity,
        valueTickerId: PVST_VALUE_TICKER_ID,
        currency: getTickerUsingId(PVST_VALUE_TICKER_ID).shortName,
        valueExchangeRate: getExchangeRateDetails(portfolioTicker.id, 1),
        rate: JSON.stringify({ u: 1, t: portfolioTicker.id, p: 0 })
      };
    } else if (asset.value !== undefined && asset.valueTickerId) {
      return {
        value: asset.value,
        valueTickerId: asset.valueTickerId,
        currency: getTickerUsingId(asset.valueTickerId).shortName
      };
    }
  }

  getAssetGridData(currency, assets) {
    var rows = [];
    let nextSortKey = null;
    for (const asset of assets) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyAssetRow(nextSortKey, asset);
      row.id = asset.id;
      row.cells[0].isUpdate = this.locationMap[asset.id] && this.locationMap[asset.id].isCustodian === true;
      row.cells[1].value =
        this.locationMap[asset.id]?.isCustodian === true
          ? this.locationMap[asset.id]?.name
          : this.getNameFromExtractedData(asset);
      row.cells[1].description = this.getLocationPath(true, this.locationMap[asset.id]);
      row.cells[2].isEditable = asset.quantity !== undefined;
      row.cells[2].value = asset.quantity && asset.tickerId ? getTickerUsingId(asset.tickerId).shortName : undefined;
      row.cells[2].description = asset.tickerId
        ? exchangeCodeToNameMap[getTickerUsingId(asset.tickerId).market]
        : undefined;
      row.cells[3].value = asset.quantity;
      row.cells[4].value = asset.cost;
      row.cells[4].currency = asset.costTickerId
        ? getTickerUsingId(asset.costTickerId).shortName
        : this.props.currentPortfolio.currency;
      row.cells[4].setExchangeRateDetails(
        this.props.currentPortfolio.currency,
        getExchangeRate(row.cells[4].currency, this.props.currentPortfolio.currency)
      );

      const assetDetails = this.getAssetDetails(asset);
      row.cells[5].value = assetDetails.value;
      row.cells[5].currency = assetDetails.currency;
      row.cells[5].exchangeRateDetails = assetDetails.valueExchangeRate;
      row.cells[5].rate = assetDetails.rate;

      if (assetDetails.rate && assetDetails.valueTickerId === PVST_VALUE_TICKER_ID) {
        const rateParsed = JSON.parse(assetDetails.rate);
        row.cells[5].useRateFromExchangeRateDetails = true;
        row.cells[5].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
      }

      rows.push(row);
    }

    const section = this.getAssetEmptySection(0, "1");
    section.rows = rows;

    const noQuantityPresent = assets.filter(item => item.quantity === undefined).length === assets.length;
    const noCostPresent = assets.filter(item => item.cost === undefined).length === assets.length;
    if (noQuantityPresent) {
      section.columns[2].hide = true;
      section.columns[3].hide = true;
    }
    if (noCostPresent) {
      section.columns[4].hide = true;
    }

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getAssetEmptySection(forIndex, sortKey) {
    const badgeColumn = new GridColumnData("", false, false, true);
    badgeColumn.width = "20px";
    const nameColumn = new GridColumnData(i18n.t("asset"), false, false, false);
    const tickerColumn = new GridColumnData(i18n.t("ticker"), false, false, false);
    const quantityColumn = new GridColumnData(i18n.t("qty"), false, false, false);
    quantityColumn.width = "50px";
    const costColumn = new GridColumnData(i18n.t("cost"), false, false, false);
    const valueColumn = new GridColumnData(i18n.t("value"), false, false, false);
    const optionsColumn = new GridColumnData(null, false, false, true);
    const columns = [badgeColumn, nameColumn, tickerColumn, quantityColumn, costColumn, valueColumn, optionsColumn];

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 4, 5, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyAssetRow(sortKey, asset) {
    const badgeCell = new GridCellData(cellType.CLICKABLE_TEXT, "", null);
    badgeCell.width = "20px";
    badgeCell.textAlignment = "right";
    badgeCell.getCellStyles = (rowIndex, cellIndex) => {
      return { cursor: "auto" };
    };
    badgeCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <BadgeContainer isUpdate={cell.isUpdate}>
          <BadgeText isUpdate={cell.isUpdate}>{cell.isUpdate ? i18n.t("update") : i18n.t("new")}</BadgeText>
        </BadgeContainer>
      );
    };
    const nameCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("asset"), null);
    nameCell.textAlignment = "left";
    nameCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      return (
        <CellDropDownContainer>
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    nameCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showLocationPickerForAssetRow: this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };

    const tickerCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("ticker"), null);
    tickerCell.textAlignment = "left";
    tickerCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <CellDropDownContainer>
          {cell.value ? null : <TickerCellPlaceholder>{i18n.t("select")}</TickerCellPlaceholder>}
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    tickerCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showTickerPickerForAssetRow: this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };
    const quantityCell = new GridCellData(cellType.NUMBER, i18n.t("qty"), null);
    quantityCell.width = "50px";
    const costCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("cost"),
      null,
      this.props.currentPortfolio.currency
    );
    quantityCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return cell.value ? null : <EmptyCellIndicator>{"---"}</EmptyCellIndicator>;
    };
    costCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.assetGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return cell.value ? null : <EmptyCellIndicator>{"---"}</EmptyCellIndicator>;
    };
    const valueCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("value"),
      null,
      this.props.currentPortfolio.currency
    );
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [badgeCell, nameCell, tickerCell, quantityCell, costCell, valueCell, optionsCell];

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      const hasQuantity = row.cells[3].value !== undefined;
      const hasCost = row.cells[4].value !== undefined;
      return [
        [
          { ...contextMenuItemType.CLEAR_TICKER, disabled: !hasQuantity },
          { ...contextMenuItemType.CLEAR_COST, disabled: !hasCost }
        ],
        [contextMenuItemType.DELETE]
      ];
    };
    return rowData;
  }

  handleDebtChange(newGridData) {
    this.setState({ debtGridData: newGridData });
  }

  getDebtGridData(currency, debts) {
    var rows = [];
    let nextSortKey = null;
    for (const debt of debts) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyDebtRow(nextSortKey, debt);
      row.id = debt.id;
      row.cells[0].isUpdate = this.locationMap[debt.id] && this.locationMap[debt.id].isCustodian === true;
      row.cells[1].value =
        this.locationMap[debt.id]?.isCustodian === true ? this.locationMap[debt.id]?.name : debt.name;
      row.cells[1].description = this.getLocationPath(false, this.locationMap[debt.id]);
      row.cells[2].value = debt.quantity || debt.value;
      row.cells[2].currency = debt.valueTickerId
        ? getTickerUsingId(debt.valueTickerId).shortName
        : this.props.currentPortfolio.currency;
      rows.push(row);
    }

    const section = this.getDebtEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getDebtEmptySection(forIndex, sortKey) {
    const badgeColumn = new GridColumnData("", false, false, true);
    badgeColumn.width = "20px";
    const nameColumn = new GridColumnData(i18n.t("debt"), false, false, false);
    const valueColumn = new GridColumnData(i18n.t("balance"), false, false, false);
    const optionsColumn = new GridColumnData(null, false, false, true);
    const columns = [badgeColumn, nameColumn, valueColumn, optionsColumn];

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 2, 2, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyDebtRow(sortKey, debt) {
    const badgeCell = new GridCellData(cellType.CLICKABLE_TEXT, "", null);
    badgeCell.width = "20px";
    badgeCell.textAlignment = "right";
    badgeCell.getCellStyles = (rowIndex, cellIndex) => {
      return { cursor: "auto" };
    };
    badgeCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.debtGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <BadgeContainer isUpdate={cell.isUpdate}>
          <BadgeText isUpdate={cell.isUpdate}>{cell.isUpdate ? i18n.t("update") : i18n.t("new")}</BadgeText>
        </BadgeContainer>
      );
    };
    const nameCell = new GridCellData(cellType.CLICKABLE_TEXT, i18n.t("debt"), null);
    nameCell.textAlignment = "left";
    nameCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      return (
        <CellDropDownContainer>
          <DownArrow />
        </CellDropDownContainer>
      );
    };
    nameCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.setState({
        showLocationPickerForDebtRow: this.state.debtGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex]
      });
    };

    const valueCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("balance"),
      null,
      this.props.currentPortfolio.currency
    );
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [badgeCell, nameCell, valueCell, optionsCell];

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      return [[contextMenuItemType.DELETE]];
    };
    return rowData;
  }

  handleAssetRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.CLEAR_TICKER.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[5].currency = this.props.currentPortfolio.currency;
      row.cells[2].value = undefined;
      row.cells[2].description = undefined;
      this.setState({ assetGridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.CLEAR_COST.id) {
      const newGridData = this.state.assetGridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[4].currency = undefined;
      row.cells[4].value = undefined;
      this.setState({ assetGridData: newGridData });
    }
  }

  handleTickerPickerDismiss() {
    this.setState({ showTickerPickerForAssetRow: null });
  }

  handleTickerSelection(value) {
    const row = this.state.showTickerPickerForAssetRow;
    const ticker = getTickerUsingId(value.items[0].id);
    row.cells[2].value = ticker.shortName;
    row.cells[2].description = exchangeCodeToNameMap[ticker.market];
    row.cells[5].currency = ticker.shortName;
    this.setState({ showTickerPickerForAssetRow: null });
  }

  handleLocationPickerDismiss() {
    this.setState({ showLocationPickerForAssetRow: null, showLocationPickerForDebtRow: null });
  }

  handleAssetLocationSelection(row, value) {
    this.locationMap[row.id] = value;
    row.cells[0].isUpdate = this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true;
    row.cells[1].description = this.getLocationPath(true, value);
    this.setState({ assetGridData: this.state.assetGridData });
  }

  handleDebtLocationSelection(row, value) {
    this.locationMap[row.id] = value;
    row.cells[0].isUpdate = this.locationMap[row.id] && this.locationMap[row.id].isCustodian === true;
    row.cells[1].description = this.getLocationPath(false, value);
    this.setState({ debtGridData: this.state.debtGridData });
  }

  handleImportButtonClick(e) {
    let custodianIdsToUpdate = [];
    const assetRows = this.state.assetGridData.sheets[0].sections[0].rows.filter(
      item => !this.locationMap[item.id] === false && item.cells[5].value !== undefined
    );
    const debtRows = this.state.debtGridData.sheets[0].sections[0].rows.filter(
      item => !this.locationMap[item.id] === false && item.cells[1].value !== undefined
    );

    let sectionIdsCreated = [];
    const updateOrInsertRow = (extractedData, row, nameCell, costCell, valueCell) => {
      const location = this.locationMap[row.id];
      if (location.isCustodian === true) {
        const propertiesToUpdate = {
          value: valueCell.value,
          valueTickerId: getTickerUsingShortName(valueCell.currency).id,
          srcName: this.getNameFromExtractedData(extractedData)
        };
        if (valueCell.exchangeRateDetails) {
          propertiesToUpdate.valueExchangeRate = valueCell.exchangeRateDetails;
        }
        if (valueCell.rate) {
          propertiesToUpdate.rate = valueCell.rate;
        }
        if (costCell && costCell.value !== undefined) {
          propertiesToUpdate.cost = costCell.value;
          propertiesToUpdate.costTickerId = getTickerUsingShortName(costCell.currency).id;
          propertiesToUpdate.costExchangeRate = costCell.exchangeRateDetails;
          propertiesToUpdate.costType = costType.MANUAL;
        }
        this.props.updateCustodian(false, location.id, propertiesToUpdate, false);
        custodianIdsToUpdate.push(location.id);
      } else {
        const section = location.isSection
          ? location
          : sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, location.id)[0];
        const newCustodian = {
          id: getUuid(),
          name: nameCell.value,
          sectionId: section.id,
          value: valueCell.value,
          valueTickerId: getTickerUsingShortName(valueCell.currency).id,
          srcName: this.getNameFromExtractedData(extractedData)
        };
        if (valueCell.exchangeRateDetails) {
          newCustodian.valueExchangeRate = valueCell.exchangeRateDetails;
        }
        if (valueCell.rate) {
          newCustodian.rate = valueCell.rate;
        }
        if (costCell && costCell.value !== undefined) {
          newCustodian.cost = costCell.value;
          newCustodian.costTickerId = getTickerUsingShortName(costCell.currency).id;
          newCustodian.costExchangeRate = costCell.exchangeRateDetails;
          newCustodian.costType = costType.MANUAL;
        }
        this.props.insertCustodianAtEndOfSection(
          this.props.currentPortfolio.id,
          newCustodian.sectionId,
          newCustodian.id,
          undefined,
          newCustodian,
          true
        );

        if (location.isUpdateSection === true && sectionIdsCreated.includes(section.id) === false) {
          sectionIdsCreated.push(location.id);
          this.props.insertSection(this.props.currentPortfolio.id, section.sheetId, {
            id: location.id,
            name: location.name,
            sortKey: location.sortKey,
            sheetId: section.sheetId
          });
        }
        this.props.updateCustodian(true, newCustodian.id, newCustodian, false);
        custodianIdsToUpdate.push(newCustodian.id);
      }
    };

    for (const row of assetRows) {
      updateOrInsertRow(
        this.state.dataExtracted.asset.find(item => item.id === row.id),
        row,
        row.cells[1],
        row.cells[4],
        row.cells[5]
      );
    }
    for (const row of debtRows) {
      updateOrInsertRow(
        this.state.dataExtracted.debt.find(item => item.id === row.id),
        row,
        row.cells[1],
        null,
        row.cells[2]
      );
    }
    this.props.updateDashboard(custodianIdsToUpdate);

    if (this.props.userPreferences.isAiDocumentImportTipShown) {
      this.handleSuccessfulImportDialogDismiss();
    } else {
      this.setState({ showSuccessfulImportDialog: true });
      this.props.updateUserPreferences({ isAiDocumentImportTipShown: true });
    }
  }

  handleSuccessfulImportDialogDismiss() {
    this.props.history.replace({
      ...this.props.location,
      hash: `${this.props.location.hash}&${hashParams.CLOSE_ADD_MODAL}=true`
    });
    setTimeout(() => {
      this.props.onDismiss();
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }, 50);
  }

  render() {
    if (this.state.showSuccessfulImportDialog) {
      return (
        <ConfirmationDialog
          dialogWidth={"650px"}
          canUserDismiss={false}
          title={i18n.t("aiImport.successfulImportTitle")}
          description={i18n.t("aiImport.successfulImportDesc")}
          positiveButtonTitle={i18n.t("gotIt")}
          handlePositiveButtonClick={this.handleSuccessfulImportDialogDismiss}
        />
      );
    }

    if (this.state.dataExtracted) {
      const gridOptions = {
        getRowStyle: () => {
          return {
            height: "49px"
          };
        }
      };

      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Title>{this.getFileName(this.state.files[0], true)}</Title>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss()} title={i18n.t("abort")} />
                  <ImportButton
                    onClick={this.handleImportButtonClick}
                    title={i18n.t("aiImport.importRows").replace("%s1%", this.getRowsToExtract())}
                  />
                </HeaderContainer>
                <ExtractedDataDescription>{i18n.t("aiImport.importRowsDesc")}</ExtractedDataDescription>
                {this.state.assetGridData && this.state.assetGridData.sheets[0].sections[0].rows.length > 0 && (
                  <GridContainer>
                    <Grid
                      gridOptions={gridOptions}
                      gridData={this.state.assetGridData}
                      getEmptyRow={this.getEmptyAssetRow}
                      onChange={this.handleAssetChange.bind(this)}
                      onRowContextMenuSelection={this.handleAssetRowContextMenuSelection}
                    />
                  </GridContainer>
                )}
                {this.state.debtGridData && this.state.debtGridData.sheets[0].sections[0].rows.length > 0 && (
                  <GridContainer>
                    <Grid
                      gridOptions={gridOptions}
                      gridData={this.state.debtGridData}
                      getEmptyRow={this.getEmptyDebtRow}
                      onChange={this.handleDebtChange.bind(this)}
                    />
                  </GridContainer>
                )}
              </ContentContainer>
              {!this.state.showTickerPickerForAssetRow === false && (
                <TickersPickerDialog
                  data={{ props: { allowUnusedTickers: true } }}
                  onVariableUpdate={this.handleTickerSelection}
                  onDismiss={this.handleTickerPickerDismiss}
                />
              )}
              {!this.state.showLocationPickerForAssetRow === false && (
                <CustodiansPickerDialog
                  category={category.ASSET}
                  description={i18n.t("aiImport.assetPickerDesc")}
                  filterResults={result => {
                    if (result.isCustodian) {
                      return !result.linkType === true;
                    } else if (result.isSheet) {
                      return (
                        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, result.id).length === 1
                      );
                    }
                    return true;
                  }}
                  returnResultWithDetails={true}
                  onVariableUpdate={data =>
                    this.handleAssetLocationSelection(this.state.showLocationPickerForAssetRow, data.items[0])
                  }
                  onDismiss={this.handleLocationPickerDismiss}
                />
              )}
              {!this.state.showLocationPickerForDebtRow === false && (
                <CustodiansPickerDialog
                  category={category.DEBT}
                  description={i18n.t("aiImport.debtPickerDesc")}
                  filterResults={result => {
                    if (result.isCustodian) {
                      return !result.linkType === true;
                    } else if (result.isSheet) {
                      return (
                        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, result.id).length === 1
                      );
                    }
                    return true;
                  }}
                  returnResultWithDetails={true}
                  onVariableUpdate={data =>
                    this.handleDebtLocationSelection(this.state.showLocationPickerForDebtRow, data.items[0])
                  }
                  onDismiss={this.handleLocationPickerDismiss}
                />
              )}
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    if (this.state.isUploading) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Title>{this.getFileName(this.state.files[0], true)}</Title>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss(true)} title={i18n.t("abort")} />
                </HeaderContainer>
                <UploadLoader
                  initialMessage={i18n.t("aiImport.waitMessage")}
                  footerMessage={i18n.t("aiImport.waitHint")}
                  footerAction={i18n.t("continueNewTab")}
                  footerActionOnClick={e => window.kuberaOpen(window.location.href.replace(window.location.hash, ""))}
                />
              </ContentContainer>
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    return (
      <DialogOverlay onDismiss={() => this.handleOverlayDismiss(false)}>
        <ImportDialog>
          <Container>
            <DocumentUploadContainer
              onDragStart={this.handleDragStart}
              onDragStop={this.handleDragStop}
              onFileDrop={this.handleFileUpload}
              disabled={this.props.isReadOnly === true}
            >
              <CommandArea isDragging={this.state.isDragging}>
                <ContentContainer>
                  {!this.state.error === true && (
                    <>
                      <AiDocumentImportIconComponent />
                      <Title>{i18n.t("aiDocumentImportDialog.title")}</Title>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: this.props.userPreferences.isAiDocumentImportTipShown
                            ? i18n.t("aiDocumentImportDialog.desc")
                            : i18n.t("aiDocumentImportDialog.firstTimeDesc")
                        }}
                      />
                      <UploadButtonContainer margin={"44px 0px 0px 0px"}>
                        <UploadButton label={i18n.t("uploadFile")} multiple={false} onChange={this.handleFileUpload} />
                        <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                      </UploadButtonContainer>
                    </>
                  )}
                  {!this.state.error === false && (
                    <>
                      {this.state.error === FILE_UNSUPPORTED_ERROR ? (
                        <AiDocumentImportIconComponent />
                      ) : (
                        <AiDocumentImportErrorIconComponent />
                      )}
                      <SubTitle>{i18n.t("aiImport")}</SubTitle>
                      <Title>
                        {this.state.error === FILE_UNSUPPORTED_ERROR
                          ? i18n.t("aiImport.fileNotSupportedTitle")
                          : i18n.t("aiImport.errorTitle")}
                      </Title>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.error === FILE_UNSUPPORTED_ERROR
                              ? i18n.t("aiImport.fileNotSupportedDesc")
                              : i18n.t("aiImport.errorDesc").replace("%s1%", this.getFileName(this.state.files[0]))
                        }}
                      />
                      {this.state.error !== FILE_UNSUPPORTED_ERROR && (
                        <ReportToKubera
                          title={i18n.t("aiImport.reportKubera")}
                          onClick={this.handleReportToKuberaClick}
                        />
                      )}
                      <UploadButtonContainer margin={"30px 0px 0px 0px"}>
                        <UploadButton
                          label={
                            this.state.error === FILE_UNSUPPORTED_ERROR
                              ? i18n.t("uploadFile")
                              : i18n.t("uploadAnotherFile")
                          }
                          multiple={false}
                          onChange={this.handleFileUpload}
                        />
                        <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                      </UploadButtonContainer>
                    </>
                  )}
                </ContentContainer>
                <DropAreaIndicator isDragging={this.state.isDragging}>
                  <DropAreaHint>{i18n.t("aiImportDropFileHint")}</DropAreaHint>
                </DropAreaIndicator>
              </CommandArea>
            </DocumentUploadContainer>
          </Container>
        </ImportDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  defaultTargetSheet: custodianSheetSelector(state, props.custodianId),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  uploadAiImportDocument,
  getAiImportExtractedData,
  updateCustodian,
  insertCustodianAtEndOfSection,
  insertSection,
  updateDashboard: updateDashboardAction,
  updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AiDocumentImportComponent));
