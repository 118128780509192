import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { isMobile } from "@kubera/common";

const isMobileDevice = isMobile();

const ConditionalContainerStyled = isMobileDevice
  ? styled(DialogOverlay)`
      width: 100vw;
      height: 100vh;

      & > div {
        padding: 0 36px;
      }

      * {
        box-sizing: border-box;
      }
    `
  : styled(DialogOverlay)``;

const GenericDialog = isMobileDevice
  ? styled(Dialog)`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 163px;
    `
  : styled(Dialog)`
      width: ${props => (props.width ? props.width : props.hasTertiaryButton === true ? "600px" : "560px")};
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 163px;
    `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${isMobileDevice ? null : "50px 45px 50px 45px"};
  padding: ${isMobileDevice ? "40px 30px" : null};
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: "ss01" on;
  text-align: left;
`;

const Description = styled.div`
  flex: 1;
  margin-top: ${isMobileDevice ? "2px" : "10px"};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: ${isMobileDevice ? "21px" : "120%"};
  font-feature-settings: "ss01" on;
  text-align: left;
  color: ${isMobileDevice ? "rgba(0, 0, 0, 0.5)" : null};
  ${props => (props.descriptionStyle ? props.descriptionStyle : "")}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${isMobileDevice ? "column" : "row"};
`;

const ErrorText = styled.p`
  margin: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  visibility: ${props => (!props.error === true ? "hidden" : "visible")}
  font-size: 11px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => props.theme.errorCLR};
`;

const PositiveButton = styled(PrimaryButton)`
  min-width: 155px;
  height: 44px;
  padding: 10px;
  font-size: ${isMobileDevice ? "14px" : "12px"};
`;

const NegativeButton = styled(SecondaryButton)`
  margin-left: ${isMobileDevice ? "0" : "20px"};
  margin-top: ${isMobileDevice ? "10px" : "0"};
  min-width: 155px;
  height: 44px;
  padding: 10px;
  font-size: ${isMobileDevice ? "14px" : "12px"};
`;

const Note = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  color: #000000b2;
  margin-top: 9px;
`;

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleOverlayDismiss() {
    if (this.props.canUserDismiss === true) {
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    }
  }

  render() {
    const title = this.props.title;
    const description = this.props.description;
    const positiveButtonTitle = this.props.positiveButtonTitle;
    const negativeButtonTitle = this.props.negativeButtonTitle;
    const tertiaryButtonTitle = this.props.tertiaryButtonTitle;
    const isLoading = this.props.isLoading;
    const errorMessage = this.props.errorMessage;

    return (
      <ConditionalContainerStyled onDismiss={this.handleOverlayDismiss}>
        <GenericDialog
          className={this.props.className}
          width={this.props.dialogWidth}
          hasTertiaryButton={!tertiaryButtonTitle === false}
        >
          <Container>
            <Title>{title}</Title>
            <Description
              descriptionStyle={this.props.descriptionStyle}
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
            <ErrorText error={errorMessage}>{!errorMessage === true ? "" : errorMessage}</ErrorText>
            <ButtonContainer>
              <PositiveButton
                onClick={this.props.handlePositiveButtonClick}
                title={positiveButtonTitle}
                isLoading={isLoading}
                data-cy="positiveButton"
              />
              {!negativeButtonTitle === false && (
                <NegativeButton
                  onClick={this.props.handleNegativeButtonClick}
                  title={negativeButtonTitle}
                  isDisabled={isLoading === true}
                  data-cy="negativeButton"
                />
              )}
              {!tertiaryButtonTitle === false && (
                <NegativeButton
                  onClick={this.props.handleTertiaryButtonClick}
                  title={tertiaryButtonTitle}
                  isDisabled={isLoading === true}
                  data-cy="tertiaryButton"
                />
              )}
            </ButtonContainer>
            {this.props.note && <Note>{this.props.note}</Note>}
          </Container>
        </GenericDialog>
      </ConditionalContainerStyled>
    );
  }
}

export default ConfirmationDialog;
