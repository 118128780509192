import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import Checkbox from "components/inputs/Checkbox";
import PrimaryButton from "components/button/PrimaryButton";
import FancyLoader from "components/loader/FancyLoader";
import { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import { convertCurrency, formatNumberWithCurrency, isCryptoLinkingService } from "@kubera/common";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const ErrorTitle = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const ErrorDescription = styled.div`
  margin-top: 10px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
`;

const RetryButton = styled(PrimaryButton)`
  margin-top: 20px;
`;

const ErrorHelpText = styled.div`
  position: absolute;
  bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 9px;
`;

const Progress = styled.div`
  width: ${props => (props.progress ? props.progress + "%" : "0%")};
  height: 2px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const SelectionButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
  height: 15px;
`;

const SelectAllButton = styled.button`
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  background: none;
`;

const SelectNoneButton = styled.button`
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0;
  margin-left: 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  background: none;
`;

const AccountListContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 27px;
  flex-direction: column;
`;

const AddButton = styled(PrimaryButton)`
  width: 155px;
`;

const CheckboxItemContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AccountCheckbox = styled(Checkbox)``;

const LinkedAccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const LinkedAccountName = styled.div`
  text-transform: capitalize;
  white-space: pre;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const LinkedAssetNames = styled.div`
  margin-top: 2px;
  margin-left: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const TitleMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  margin-bottom: 20px;
`;

const MessageContainer = styled.div`
  margin-top: 2px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const TitleImage = styled.img`
  height: 33px;
`;

const LinkedAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;

  + div {
    margin-top: 29px;
  }
`;

class SelectLinkedAccountsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.isCryptoLinkType = isCryptoLinkingService(this.props.linkType);
    this.state = {
      selectedAccounts: this.getDefaultSelectedAccounts(),
      titleImageLoadState: null
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectNone = this.handleSelectNone.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (this.props.accounts !== oldProps.accounts) {
      if (this.props.enableLinkedAccounts === true) {
        this.setState({ selectedAccounts: this.props.accounts.filter(item => item.linkedCustodianId !== "") });
      }
    }
  }

  handleCheckboxChange(isChecked, account) {
    if (isChecked === true) {
      this.setState({ ...this.state, selectedAccounts: [...this.state.selectedAccounts, account] });
    } else {
      const accountIndex = this.state.selectedAccounts.findIndex(a => a.id === account.id);
      this.state.selectedAccounts.splice(accountIndex, 1);
      this.setState({ ...this.state, selectedAccounts: this.state.selectedAccounts });
    }
  }

  getDefaultSelectedAccounts() {
    if (!this.props.accounts) {
      return [];
    }
    if (this.isCryptoLinkType) {
      return this.getAllUnlinkedAccounts();
    }
    if (this.props.enableLinkedAccounts === true) {
      return this.props.accounts.filter(item => item.linkedCustodianId !== "");
    }
    return [];
  }

  getAllUnlinkedAccounts() {
    if (!this.props.accounts) {
      return [];
    }

    var selectedAccounts = [];
    for (const account of this.props.accounts) {
      if (account.linkedCustodianId === "") {
        selectedAccounts.push(account);
      }
    }
    return selectedAccounts;
  }

  handleSelectAll() {
    this.setState({ selectedAccounts: this.getAllUnlinkedAccounts() });
  }

  handleSelectNone() {
    this.setState({ ...this.state, selectedAccounts: [] });
  }

  handleAddButtonClick() {
    const selectedAccounts = this.state.selectedAccounts;

    if (this.props.onAddAccount) {
      this.props.onAddAccount(selectedAccounts);
    }
  }

  isLinkableAccountListEmpty() {
    if (!this.props.accounts === true) {
      return true;
    }
    for (const account of this.props.accounts) {
      if (account.linkedCustodianId === "") {
        return false;
      }
    }
    return true;
  }

  getAccountAssetNamesString = assetNames => {
    if (!assetNames === true) {
      return "";
    }
    var assets = assetNames.length <= 3 ? assetNames : assetNames.slice(0, 4);
    return assets.join(", ") + (assetNames.length > 3 ? " & more" : "");
  };

  getAccountLists = linkedAccounts => {
    const { mode, portfolio } = this.props;
    const { selectedAccounts } = this.state;

    if (!linkedAccounts === false && !selectedAccounts === false && this.props.isPending === false) {
      if (mode === linkAccountMode.MANAGE) {
        return (
          <AccountListContainer>
            <LinkedAccountsContainer>
              <TitleMessage>{i18n.t("manageAccount.newAccountsTitle")}</TitleMessage>
              {linkedAccounts.filter(item => !item.linkedCustodianId === true).length > 0 ? (
                linkedAccounts
                  .filter(item => !item.linkedCustodianId === true)
                  .map((account, index) => (
                    <LinkedAccountDetails key={index}>
                      <CheckboxItemContainer key={index}>
                        <AccountCheckbox
                          label={`${account.accountName} <span style='color:rgba(0, 0, 0, 0.4);'>${
                            account.accountNumber
                          }</span>• ${formatNumberWithCurrency(
                            convertCurrency(account.balance, account.currency, portfolio.currency),
                            portfolio.currency
                          )}`}
                          disabled={account.linkedCustodianId !== ""}
                          checked={
                            selectedAccounts.findIndex(a => a.id === account.id) !== -1 ||
                            account.linkedCustodianId !== ""
                          }
                          onChange={isChecked => this.handleCheckboxChange(isChecked, account)}
                        />
                      </CheckboxItemContainer>
                      <LinkedAssetNames isManageFlow={true}>
                        {this.getAccountAssetNamesString(account.assetNames)}
                      </LinkedAssetNames>
                    </LinkedAccountDetails>
                  ))
              ) : (
                <MessageContainer>{i18n.t("manageAccount.noNewAccountsMessage")}</MessageContainer>
              )}
            </LinkedAccountsContainer>
            <LinkedAccountsContainer>
              <TitleMessage>{i18n.t("manageAccount.alreadyAddedAccountsTitle")}</TitleMessage>
              {linkedAccounts
                .filter(item => !item.linkedCustodianId === false)
                .map((account, index) => (
                  <LinkedAccountDetails key={index}>
                    <LinkedAccountName>
                      {account.accountName +
                        (account.accountNumber ? " " + account.accountNumber : "") +
                        " • " +
                        formatNumberWithCurrency(
                          convertCurrency(account.balance, account.currency, portfolio.currency),
                          portfolio.currency
                        )}
                    </LinkedAccountName>
                    <LinkedAssetNames>{this.getAccountAssetNamesString(account.assetNames)}</LinkedAssetNames>
                  </LinkedAccountDetails>
                ))}
            </LinkedAccountsContainer>
          </AccountListContainer>
        );
      } else {
        return (
          <AccountListContainer>
            <TitleMessage>{this.props.successMessage || i18n.t("zerionmodal.successMessage")}</TitleMessage>
            {linkedAccounts.map((account, index) => (
              <LinkedAccountDetails key={index}>
                <LinkedAccountName>
                  {account.accountName +
                    (account.accountNumber ? " " + account.accountNumber : "") +
                    " • " +
                    formatNumberWithCurrency(
                      convertCurrency(account.balance, account.currency, portfolio.currency),
                      portfolio.currency
                    )}
                </LinkedAccountName>
                <LinkedAssetNames>{this.getAccountAssetNamesString(account.assetNames)}</LinkedAssetNames>
              </LinkedAccountDetails>
            ))}
          </AccountListContainer>
        );
      }
    }
  };

  titleImageError = () => {
    this.setState({
      titleImageLoadState: false
    });
  };
  titleImageLoaded = () => {
    this.setState({
      titleImageLoadState: true
    });
  };

  render() {
    const isPending = this.props.isPending;
    const error = this.props.error;
    const title = this.props.title;
    const { details } = this.props;
    const showTitleImg = this.state.titleImageLoadState === null || this.state.titleImageLoadState;
    const showTitleText = this.state.titleImageLoadState === null || !this.state.titleImageLoadState;
    const imgUrl = `${process.env.PUBLIC_URL}/images/connection_icons/${details.icon || details.searchIcon || ""}`;

    if (isPending === true) {
      return (
        <Container>
          <Title>
            {showTitleImg && (
              <TitleImage
                src={imgUrl}
                alt={details.linkProviderId}
                onLoad={this.titleImageLoaded}
                onError={this.titleImageError}
              />
            )}
            {showTitleText && title}
          </Title>

          <FancyLoader
            initialMessage={i18n.t("linkAccount.initialLoaderMessage")}
            footerMessage={i18n.t("linkAccount.selectAccountsHeadsUpMessage")}
          />
        </Container>
      );
    }

    if (!error === false) {
      return (
        <Container>
          <Title>
            {showTitleImg && (
              <TitleImage
                src={imgUrl}
                alt={details.linkProviderId}
                onLoad={this.titleImageLoaded}
                onError={this.titleImageError}
              />
            )}
            {showTitleText && title}
          </Title>

          <ErrorTitle>{i18n.t("linkAccount.selectAccountFailureTitle")}</ErrorTitle>
          <ProgressBar>
            <Progress animate={false} progress={0} />
          </ProgressBar>
          <ErrorDescription
            dangerouslySetInnerHTML={{
              __html: i18n.t("linkAccount.selectAccountFailureDescription")
            }}
          />
          <RetryButton title={i18n.t("linkAccount.selectAccountRetryButton")} onClick={this.props.onRefresh} />
          <ErrorHelpText
            dangerouslySetInnerHTML={{
              __html: i18n.t("linkAccount.selectAccountHelpText")
            }}
          />
        </Container>
      );
    }

    const accounts = this.props.accounts;
    const selectedAccounts = this.state.selectedAccounts;
    const noAccountsAvailableForLinking = this.isLinkableAccountListEmpty();

    return (
      <Container>
        <Title>
          {showTitleImg && (
            <TitleImage
              src={imgUrl}
              alt={details.linkProviderId}
              onLoad={this.titleImageLoaded}
              onError={this.titleImageError}
            />
          )}
          {showTitleText && title}
        </Title>

        {this.isCryptoLinkType === false && noAccountsAvailableForLinking === false && (
          <SelectionButtonContainer>
            <SelectAllButton onClick={this.handleSelectAll}>{i18n.t("linkAccount.selectAllAccounts")}</SelectAllButton>
            <SelectNoneButton onClick={this.handleSelectNone}>
              {i18n.t("linkAccount.selectNoneAccounts")}
            </SelectNoneButton>
          </SelectionButtonContainer>
        )}
        {this.isCryptoLinkType === true && this.getAccountLists(accounts)}
        {this.isCryptoLinkType === false && (
          <AccountListContainer>
            {accounts.map((account, index) => {
              return (
                <CheckboxItemContainer key={index}>
                  <AccountCheckbox
                    label={`${account.accountName}  <span style='color:rgba(0, 0, 0, 0.4);'>${account.accountNumber}</span>`}
                    disabled={account.linkedCustodianId !== "" && !this.props.enableLinkedAccounts === true}
                    checked={
                      selectedAccounts.findIndex(a => a.id === account.id) !== -1 ||
                      (account.linkedCustodianId !== "" && !this.props.enableLinkedAccounts === true)
                    }
                    onChange={isChecked => this.handleCheckboxChange(isChecked, account)}
                  />
                </CheckboxItemContainer>
              );
            })}
          </AccountListContainer>
        )}
        <AddButton
          isDisabled={selectedAccounts.length === 0 && noAccountsAvailableForLinking === false}
          onClick={this.handleAddButtonClick}
          title={noAccountsAvailableForLinking === true ? i18n.t("ok") : i18n.t("add")}
        />
      </Container>
    );
  }
}
export default SelectLinkedAccountsComponent;
