export const downloadFile = (url, name, target = "_self") => {
  var element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", name);
  element.setAttribute("target", target);
  element.style.display = "none";

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const generateThumbnailForFile = (file, onCompletion) => {
  if (isImageFile(file.type) === false) {
    onCompletion(null);
    return;
  }
  const thumbnailSize = 300;
  const maxThumbnailSize = 1000;
  const reader = new FileReader();
  reader.onload = event => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      const elem = document.createElement("canvas");
      const max = Math.max(img.width, img.height);
      const min = Math.min(img.width, img.height);

      let compressionRatio = 1;
      if (min > thumbnailSize) {
        compressionRatio = min / thumbnailSize;
      } else if (max > maxThumbnailSize) {
        compressionRatio = max / thumbnailSize;
      }
      elem.width = img.width / compressionRatio;
      elem.height = img.height / compressionRatio;

      const ctx = elem.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width / compressionRatio, img.height / compressionRatio);
      ctx.canvas.toBlob(
        blob => {
          const thumbnailFile = new File([blob], "thumbnail_" + file.name, {
            type: file.type
          });
          onCompletion(thumbnailFile);
        },
        file.type,
        0.9
      );
    };
  };
  reader.onerror = error => {
    onCompletion(null);
  };
  reader.readAsDataURL(file);
};

export const isImageFile = fileMimeType => {
  if (fileMimeType) {
    return fileMimeType.startsWith("image/");
  }
  return false;
};

export const copyToClipboard = content => {
  try {
    var input = document.createElement("textarea");
    input.innerHTML = content;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  } catch (e) {
    console.log(e);
  }
};

export const getFileNameWithoutExtension = fileName => {
  if (!fileName) {
    return fileName;
  }
  const extensionIndex = fileName.lastIndexOf(".");
  if (extensionIndex === -1 && extensionIndex !== fileName.length - 1) {
    return fileName;
  }
  return fileName.substring(0, extensionIndex);
};
